import React from "react";
import DataTable from "react-data-table-component";
import { BsFillCaretDownFill } from "react-icons/bs";
import Loader from "./Loader";

const Table = ({ columns, data, selectableRows, onSelectedRowsChange }) => {
  // const getStatusText = (status) => {
  //   return status === "1"
  //     ? "Pending"
  //     : status === "2"
  //     ? "DPA Review"
  //     : status === "3"
  //     ? "Verified"
  //     : status === "4"
  //     ? "Nodal Officer Review"
  //     : status === "5"
  //     ? "Approved"
  //     : status === "6"
  //     ? "Final Approved"
  //     : status === "7"
  //     ? "Rejected"
  //     : status === "8"
  //     ? "Under Process"
  //     : status === "9"
  //     ? "Released"
  //     : "";
  // };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.status.toLowerCase();
    const b = rowB.status.toLowerCase();
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  let newobj = columns.find((a) => a.name.toLowerCase() === "status");
  if (!!newobj) {
    newobj.sortFunction = caseInsensitiveSort;
  }

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    table: {
      style: {
        border: "1px solid rgba(0,0,0,.12)",
      },
    },
    headCells: {
      style: {
        padding: "5px",
        "&:not(:last-child)": {
          borderRight: "1px solid rgba(0,0,0,.12)",
        },
      },
    },
    cells: {
      style: {
        padding: "5px",
        wordBreak: "break-word",
        lineHeight: "1.4",
        "&:not(:last-child)": {
          borderRight: "1px solid rgba(0,0,0,.12)",
        },
      },
    },
    rows: {
      style: {
        borderColor: "#000",
        "&:hover": {
          outline: "0 !important",
          borderColor: "rgba(0,0,0,.12) !important",
        },
      },
    },
  };

  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <div className="form-check  form-check-inline m-0">
          <input
            {...rest}
            type="checkbox"
            className="form-check-input"
            style={{ width: "20px", height: "20px" }}
            ref={ref}
            onClick={onClick}
          />
        </div>
      </>
    );
  });

  return (
    <DataTable
      columns={columns}
      data={data}
      sortIcon={<BsFillCaretDownFill />}
      customStyles={customStyles}
      defaultSortFieldId={1}
      progressComponent={<Loader />}
      striped
      highlightOnHover
      pagination
      dense
      selectableRows={selectableRows}
      onSelectedRowsChange={onSelectedRowsChange}
      selectableRowsComponent={Checkbox}
    />
  );
};

export default Table;
