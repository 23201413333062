import axios from "axios";

let cancelToken;
const CommonApi = async (url, method, body, cancelTokenVar = false) => {
  let options = {
    url: url,
    method: method || "GET",
    data: body,
    responseType: "json",
  };

  if (cancelTokenVar) {
    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    options.cancelToken = cancelToken.token;
  }

  if (method === "GET") {
    options.data = undefined;
  }
  // console.log('hit');
  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // console.log(`Error from ${url} >> ${error}`);
      throw error.response;
    });
};

export default CommonApi;
